(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Main application scripts
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
require('./_modal');

// Do something after DOM is ready
(function ($) {

    // Fade in button
    var $button = $('.orders-payment .btn-feedback');
    if( $button.length ) {
        $button.delay(3000).fadeIn(1000);
    }

    // Submit feedback via ajax
    var $modal = $('#esensiModal');
    $modal.on('submit', 'form.feedback', function(_event) {
        _event.preventDefault();

        // Reset alert
        var $alert = $modal.find('div.alert-danger');
        $alert.hide();

        // Loading button
        var $button = $modal.find('.btn-danger');
        $button.html('Sending...').addClass('disabled');

        // Get the form data
        var data = $('form.orders-payment').serialize();
        data += '&message='+$modal.find('textarea').val();
        $.ajax({
            method: 'POST',
            url: $modal.find('form.feedback').attr('action'),
            data: data,
            success: function(response){
                $modal.modal('hide');
            },
            error: function(jqXHR) {
                console.log(jqXHR.responseJSON);
                $alert.show();
                $button.html('<i class="fa fa-fw fa-envelope-o"></i> Send Feedback').removeClass('disabled');
            }
        });

    });

})(window.$);

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/public/_feedback.js","/public")