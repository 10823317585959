(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Admin application scripts
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
var moment = require('moment');

/**
 * Google Analytics
 */

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments);},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

window.ga('create', 'UA-32115876-4', 'auto', {'name': 'c2c'});
window.ga('require', 'displayfeatures');
window.ga('c2c.send', 'pageview');

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PPJ5CHL');

(function($) {

    // Send events to Google Analytics
    var trackAction = function(e){
        var $el = $(e.currentTarget);
        var category = $el.data('category');
        var action = $el.data('track');
        var label = $el.data('label') ? $el.data('label') : $el.val(); // For inputs don't include data-label
        var value = $el.data('value') ? $el.data('value') : 1;
        if( label.length ) {
            window.ga('c2c.send', 'event', category, action, label, value);
        }
    };

    // Track button interactions
    $('body').on('click', 'a[data-track],[type=submit][data-track],[type=button][data-track]', trackAction);

    // Track input interactions
    $('body').on('change', 'select[data-track],input[type=radio][data-track]', trackAction);

    // Track user timing from time to load to time to click on approve
    if( $('body').is('.orders-preview'))
    {
        var loadTime = moment();
        $('body').on('click', '[data-track][data-category="Preview"][data-label="Approve"]', function(){
            var deltaTime = moment().diff(loadTime);
            window.ga('c2c.send', 'event', 'Preview', 'review', 'Review Permits', deltaTime);
        });

        // Track user timing from time to approve to confirm approval
        var confirmTime;
        $('body').on('click', '[data-track][data-category="Preview"][data-label="Approve"]', function(){
            confirmTime = moment();
        });
        $('body').on('click', '[data-track][data-category="Preview"][data-label="Pay"]', function(){
            var deltaTime = moment().diff(confirmTime);
            window.ga('c2c.send', 'event', 'Preview', 'confirm', 'Confirm Approve', deltaTime);
        });

        // Reset load time when user clicks close in modal
        $('body').on('click', '[data-track][data-category="Preview"][data-label="Close"]', function(){
            loadTime = moment();
        });
    }

    // Page load events
    $('[data-track="show"]').each(function(){
        trackAction({ currentTarget: this });
    });
})(window.$);

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/common/_ga.js","/common")