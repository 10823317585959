(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Gateway Interactions
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
//require('secure-submit');
//var moment = require('moment');

(function($)
{
    // Setup the public API keys
    var apiTestKey = 'pkapi_cert_IKkdjrfxYypf1ES1FG';
    var apiLiveKey = 'pkapi_prod_4RUIiG3TvtSbOtf9zA';

    // Get the form we'll be dealing with
    var form = $('form.admin-orders-create, form.orders-payment');

    // Enable the loading state on the submit button
    // Disable the go back button
    var submit = form.find('button[type=submit]');
    var back = form.find('a.go-back');
    var processTime;
    form.on('submit', function()
    {
        back.addClass('disabled');
        submit.button('loading').addClass('disabled');
        processTime = moment();
        form.find('[name=card_number]').val(parseInt($('#card_number').val().replace(/ /g,""),10));
        form.find('[name=exp_date]').val($('#exp_year').val()+'-'+$('#exp_month').val());
        // Add card last 4 to the form
        var card_last4 = $('#card_number').val().slice(-4);
        form.find('[name=card_last4]').val(card_last4);
    });

    // Check if in testmode
    var testmode = (
        document.location.host.indexOf('staging.') !== -1 ||
        document.location.host.indexOf('.dev') !== -1 ||
        document.location.search.indexOf('debug=testmode') !== -1 ||
        form.find('[name=debug]').val() === 'testmode'
    );

    /* Bind Secure Submit gateway to form
    form.SecureSubmit({

        public_key: testmode ? apiTestKey : apiLiveKey,

        // Secure Submit returns an error response
        error: function (response)
        {
            var deltaTime = moment().diff(processTime);
            window.ga('c2c.send', 'event', 'Payment', 'process', 'Invalid Card', deltaTime);

            // Hide form errors
            form.find('.has-error').removeClass('has-error');
            form.find('.card-errors').hide();
            form.find('.alert-danger').hide();

            // Highlight the fields with errors
            switch(response.param)
            {
                case 'card.number':
                    form.find('#card_number')
                        .parents('.form-group')
                        .addClass('has-error');
                    break;
                case 'card.cvc':
                    form.find('#card_cvc')
                        .parents('.form-group')
                        .addClass('has-error');
                    break;
                case 'card.exp_month':
                    form.find('#exp_month')
                        .parents('.form-group')
                        .addClass('has-error');
                    break;
                case 'card.exp_year':
                    form.find('#exp_year')
                        .parents('.form-group')
                        .addClass('has-error');
                    break;
            }

            // Show the error message
            var message = response.message !== undefined ? response.message : response;
            form.find('.card-errors')
                .text(message)
                .show();

            // Scroll to the top of the page
            var y = form.find('.card-errors').offset().top - 25;
            $('body,html').animate({scrollTop: y}, 1000);

            // Reset the submit button and the go back button
            submit.button('reset').removeClass('disabled');
            back.removeClass('disabled');

            // Track errors
            window.ga('c2c.send', 'event', 'Payment', 'process', message, 400);

            return false;
        },

        // Secure Submit returns a success response
        success: function (response)
        {
            form.find('[name=card_number]').val(parseInt($('#card_number').val().replace(/ /g,""),10));
            form.find('[name=cvc_number]').val($('#card_cvc').val());
            var deltaTime = moment().diff(processTime);
            window.ga('c2c.send', 'event', 'Payment', 'process', 'Valid Card', deltaTime);

            // Hide form errors
            form.find('.has-error').removeClass('has-error');
            form.find('.card-errors').hide();
            form.find('.alert-danger').hide();

            // Add card last 4 to the form
            var card_last4 = response.card.number.slice(-4);
            form.find('[name=card_last4]').val(card_last4);

            // Track success
            window.ga('c2c.send', 'event', 'Payment', 'process', 'Card Token', 200);
            window.ga('c2c.send', 'event', 'Payment', 'submit', 'Order', 1);

            return true;
        }
    });*/
})(window.$);

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/common/_gateway.js","/common")