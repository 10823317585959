(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Order Form Interactions
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
require('datetimepicker');
var moment = require('moment-timezone');
var calculate = require('./_calculator');
var states = [];
var permit_types = [];
var form, step1interval, step2interval;
var em = ['<div class="alert alert-danger">Please correct the following errors before continuing to the next step.<ul>','</ul></div>'];

(function($)
{
    // Get the order forms these interactions apply to
    form = $('form.admin-permits-issue, form.admin-orders-create, form.orders-create');

    // Bind datetimepicker to effective date fields
    // @link http://eonasdan.github.io/bootstrap-datetimepicker
    form.find('.input-group.date').datetimepicker({
        pickTime: false,
        icons: {
            time: 'far fa-fw fa-clock',
            date: 'fa fa-fw fa-calendar',
            up: 'fa fa-fw fa-arrow-up',
            down: 'fa fa-fw fa-arrow-down'
        },
        minDate: moment().startOf('day').format('MM/DD/YYYY')
    });
    form.find('.input-group.date2').datetimepicker({
        pickTime: false,
        icons: {
            time: 'far fa-fw fa-clock',
            date: 'fa fa-fw fa-calendar',
            up: 'fa fa-fw fa-arrow-up',
            down: 'fa fa-fw fa-arrow-down'
        },
    });
    form.find('.input-group.time').datetimepicker({
        format: 'hh:mm A',
        pickDate: false,
        pickTime: true,
        icons: {
            time: 'far fa-fw fa-clock',
            date: 'fa fa-fw fa-calendar',
            up: 'fa fa-fw fa-arrow-up',
            down: 'fa fa-fw fa-arrow-down'
        }
    });

    form.on('submit',function(e){
        // Store everything locally in case they return by back button
        localStorage.setItem('states', JSON.stringify(states));
        localStorage.setItem('permit_types', JSON.stringify(permit_types));
        localStorage.setItem('order', JSON.stringify(form.serializeArray()));
    });

    $('#addStateBtn').on('click',function(e) {
        e.preventDefault();
        form.find('[name=state]').focus();
        $('#permits-panel')[0].scrollIntoView();
    });

    // Toggle types of permits allowed based on state
    form.on('change', '[name=state]', function()
    {
        var el = $(this);
        var state = el.val();

        $('.state-warning').hide();
        //if (state == 'AL') $('.state-warning').show();

        $('.instant-warning').hide();
        $('.click-warning').hide();
        //$('.click-danger').hide();

        // Enable types
        form.find('.permit-type .radio').removeClass('disabled click-permit');
        form.find('#permit-types label i.fas').removeClass('fa-bolt fa-clock').addClass('fa-bolt');
        form.find('[name=type]').prop('disabled', false);

        // Disable fuel types for a given state
        if( (window.permit.types.fuel.indexOf(state) === -1) && (window.permit.click.fuel.indexOf(state) === -1) )
        {
            form.find('.permit-type .radio.fuel').addClass('disabled');
            form.find('.permit-type .radio.combo').addClass('disabled');
            form.find('[name=type][value=fuel],[name=type][value=combo]').prop('disabled', true);
        } else if (window.permit.types.fuel.indexOf(state) === -1) {
            form.find('.permit-type .radio.fuel, .permit-type .radio.combo').addClass('click-permit').find('i').addClass('fa-clock');
        }

        // Disable trip types for a given state
        if( (window.permit.types.trip.indexOf(state) === -1) && (window.permit.click.trip.indexOf(state) === -1) )
        {
            form.find('.permit-type .radio.trip').addClass('disabled');
            form.find('.permit-type .radio.combo').addClass('disabled');
            form.find('[name=type][value=trip],[name=type][value=combo]').prop('disabled', true);
        } else if (window.permit.types.trip.indexOf(state) === -1) {
            form.find('.permit-type .radio.trip, .permit-type .radio.combo').addClass('click-permit').find('i').addClass('fa-clock');
        }

        if( window.permit.types.combo.indexOf(state) !== -1 )
        {
            form.find('.permit-type .radio.combo').removeClass('disabled');
            form.find('[name=type][value=combo]').prop('disabled', false);
        } else if (window.permit.click.combo.indexOf(state) !== -1) {
            form.find('.permit-type .radio.combo').addClass('click-permit');
            form.find('.permit-type .radio.combo').removeClass('disabled');
            form.find('[name=type][value=combo]').prop('disabled', false);
        }

        // Select first one by default so we have at least one selected
        form.find('[name=type]:enabled').eq(0).prop('checked',true);

        if (state == '') {
            $('#permit-types').hide();
            $('#step1').html('Add another state: <i class="fas fa-arrow-down"></i>').show();
            clearInterval(step2interval);
            animateStep('1');
            step1interval = setInterval(function(){animateStep('1');}, 2500);
        } else {
            $('#permit-types').show();
            clearInterval(step1interval);
            $('#step1').hide();
            animateStep('2');
            step2interval = setInterval(function(){animateStep('2');}, 2500);
        }

        // Calculate the totals
        //calculate(form);

    });

    form.on('change', '[name=state],[name=type]', function(e){
        //if(form.find('[name=type][value='+type+']').parent().parent().hasClass('click-permit')) {
        var state = form.find('[name=state]').val();
        var type = form.find('[name=type]:checked').val();
        if (window.permit.click[type].indexOf(state) !== -1) {
            $('.instant-warning').hide();
            $('.click-warning').show();
            var officetime = moment().tz("America/New_York");
            var nexttime = moment().tz("America/New_York");
            if (officetime.hour() >= 17 || officetime.day() == 0  || officetime.day() == 6) { // After hours, get next business day
                switch (officetime.day()) {
                    case 0:
                        nexttime.day(1);
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        nexttime.day(officetime.day()+1);
                        break;
                    case 5:
                    case 6:
                        nexttime.day(8);
                        break;
                }
                nexttime.hour(8).minute(0);
                //console.log(form.find('.input-group.date').data('DateTimePicker'));
                form.find('.input-group.date').data('DateTimePicker').setDate(nexttime);
                form.find('.input-group.date').data('DateTimePicker').setMinDate(nexttime);
                form.find('.input-group.date input').val(nexttime.format('MM/DD/YYYY')).trigger('change');
                $('#nexttime').text(nexttime.format('MM/DD/YYYY hh:mm a')+' EST');
                form.find('.input-group.time input').val(nexttime.tz(moment.tz.guess()).format('hh:mm A')).trigger('change');
                $('.click-danger').show();
            }
        } else {
            $('.click-warning').hide();
            $('.instant-warning').show();
            form.find('.input-group.date').data('DateTimePicker').setMinDate(moment());
        }
    });

    // Calculate totals whenever the permit type, or delivery method changes
    form.on('click', 'input[name=type], input[name=delivery_method]', function()
    {
        //calculate(form);
    });

    form.on('click', '#addPermitBtn', function(e)
    {
        e.preventDefault();
        var state = form.find('[name=state]').val();
        var type = form.find('[name=type]:checked').val();
        if ( ! state || ! type || inArray(state,states))
            return;

        states.push(state);
        permit_types.push(type);
        form.find('[name=states]').val(states.join(','));
        form.find('[name=permit_types]').val(permit_types.join(','));
        $('.hidden-form-blade').show();
        addFields(state,type);
        var type_text = {fuel:'IFTA (fuel)',trip:'IRP (trip)',combo:'IFTA (fuel) and IRP (trip)'};
        var icon = form.find('[name=type]:checked').parent().find('i').prop('outerHTML');
        var li = $('<li>'+icon+' '+form.find('[name=state] option:selected').text()+' '+type_text[type]+' </li>');
        var rm = $('<a href="#" data-state="'+state+'" data-type="'+type+'"><i class="fa fa-fw fa-trash text-danger"></i></a>');
        rm.on('click',function(e){
            e.preventDefault();
            removePermit($(this).data('state'),$(this).data('type'));
            $(this).parent().remove();
            if (form.find('#permitList').html().search('clock') > -1 && $('#nexttime').text().length > 0) {
                $('.click-danger').show();
            }
        });
        li.append(rm);
        form.find('#permitList').append(li);
        $('#permit-selected-none').hide();
        $('#permit-selected-body').show();
        form.find('[name=state]').val('').trigger('change');
    });

    function removePermit(state,type) {
        var type_i;
        states = $.grep(states,function(v,i){
            type_i = i;
            return v != state;
        });
        permit_types = $.grep(permit_types,function(v,i){
            return i != type_i;
        });
        $('.hidden-form-blade').hide();
        $('#permit-selected-none').show();
        $('#permit-selected-body').hide();
        resetFields();
        if (states.length) {
            form.find('[name=states]').val(states.join(','));
            form.find('[name=permit_types]').val(permit_types.join(','));
            $('.hidden-form-blade').show();
            $.each(states,function(i,s){
                var t = permit_types[i];
                addFields(s,t);
            });
            $('#permit-selected-none').hide();
            $('#permit-selected-body').show();
        }
    }
    
    // Lookup vehicle info based on LPN
    form.on('click', '#vehicleLookup', function(e)
    {
        e.preventDefault();
        $('.panel-body div.alert-danger').remove();
        var st = form.find('[name=state_of_issuance]').val();
        var lpn = form.find('[name=license_plate_number]').val().toUpperCase();
        var die = 0;
        var lookupFail = '<li>That license plate was not found. Please check that the plate number and state are correct. If you are using a temporary plate, please click the button below to input your vehicle information manually.</li>';
        var errors = '';
        
        if (!st) {
            errors += '<li>Please select a state.</li>';
            die = 1;
        }
        
        if (!(lpn.length > 3 && lpn.length < 17)) {
            errors += '<li>Please provide a license plate number.</li>';
            die = 1;
        }
        
        if (die == 1) {
            $('.vin-fieldset').parent().prepend(em[0] + errors + em[1]);
            return;
        }
        
        $.post("vLookup", {
            uid: form.find('[name=user_id]').val(),
            lpn: lpn,
            st: st,
        }).done(function(data) {
            console.log(data);
            if (data[0] == 404) {
                console.log("License plate not found.");
                $('.vin-fieldset').parent().prepend(em[0] + lookupFail + em[1]);
                $('.form-group-6').show();
            } else if (data[0] == 299) {
                form.find('[name=vehicle_id]').val(data[1]).trigger('change');
            } else {
                if ($.type(data[1]) == 'string') {
                    var data2 = JSON.parse(data[1]);
                } else {
                    var data2 = data[1];
                }
                form.find('[name=vehicle_vin]').val(data2.licensePlateLookup.vin);
                form.find('[name=vehicle_make]').val(data2.licensePlateLookup.make);
                form.find('[name=vehicle_model]').val(data2.licensePlateLookup.model);
                form.find('[name=vehicle_year]').val(data2.licensePlateLookup.year);
                form.find('[name=vehicle_style]').val(data2.licensePlateLookup.style);
                $('.form-group-2').show();
                $('.form-group-5').show();
                $('.form-group-4').hide();
                $('.form-group-6').hide();
            }
        });
        
        /*$.get('https://licenseplatedata.com/consumer-api/KIMI-LPDHHTP35/' + st + '/' + lpn, function(data, status){
            alert("Data: " + data + "\nStatus: " + status);
        });*/
    });
    
    /* Update user's vehicles
    form.on('click', '#vehicleSave', function (e) {
        e.preventDefault();
        $('#vehicleSave').addClass('disabled');
        $('.panel-body div.alert-danger').remove();
        
        $.ajaxSetup({
            headers: {
               'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        
        $.post("vUpdate", {
            uid: form.find('[name=user_id]').val(),
            id: form.find('[name=vehicle_id]').val(),
            vin: form.find('[name=vehicle_vin]').val(),
            ins: form.find('[name=insurance_name]').val(),
            pol: form.find('[name=insurance_policy_number]').val(),
            mak: form.find('[name=vehicle_make]').val(),
            mod: form.find('[name=vehicle_model]').val(),
            yr: form.find('[name=vehicle_year]').val(),
            stl: form.find('[name=vehicle_style]').val(),
            cty: form.find('[name=vehicle_registration_county]').val(),
            wgt: form.find('[name=max_gross_weight]').val(),
            ft: form.find('[name=fuel_type]').val(),
            axl: form.find('[name=vehicle_axles]').val(),
            lpn: form.find('[name=license_plate_number]').val(),
            exp: form.find('[name=license_plate_expiration]').val(),
            coi: form.find('[name=city_of_issuance]').val(),
            st: form.find('[name=state_of_issuance]').val(),
        }).done(function(data) {
            if (data == 200) {
                $('#vehicleSave').html('<i class="fa fa-fw fa-check text-success"></i> Changes Saved');
                setTimeout(function() {
                    $('#vehicleSave').html('<i class="fa fa-fw fa-plus-circle text-success"></i> Save Changes');
                }, 3000);
            } else if (typeof data == 'string') {
                if (data.includes('<option')) {
                    $('select[name=vehicle_id] option:selected').removeAttr('selected');
                    form.find('[name=vehicle_id]').append(data);
                    $('#vehicleSave').html('<i class="fa fa-fw fa-check text-success"></i> New Vehicle Created');
                    $('.orders-create button[type=submit]').removeClass('disabled');
                    setTimeout(function() {
                        $('#vehicleSave').html('<i class="fa fa-fw fa-plus-circle text-success"></i> Save Changes');
                    }, 3000);
                }
            } else {
                $('#vehicleSave').html('<i class="fa fa-fw fa-plus-circle alert-danger" style="transform: rotate(45deg);"></i> Error');
                var errors = '';
                $.each(data, function (i) {
                    errors += '<li>' + data[i] + '</li>';
                });
                $('.vin-fieldset').parent().prepend('<div class="alert alert-danger">Please correct the following errors before continuing to the next step.<ul>' + errors + '</ul></div>');
                setTimeout(function() {
                    $('#vehicleSave').html('<i class="fa fa-fw fa-plus-circle text-success"></i> Save Changes');
                }, 3000);
            }
        });
        $('#vehicleSave').removeClass('disabled');
    }); */
    
    // Clears the vehicle section of the form
    form.on('click', '#vehicleClear', function (e) {
        e.preventDefault();
        $('.panel-body div.alert-danger').remove();
        var fieldsets = form.find('.insurance-fieldset, .make-fieldset, .vin-fieldset, .license-fieldset');
        fieldsets.attr('disabled', false);
        fieldsets.find(':input').val('');
        $('.form-group-2').hide();
        $('.form-group-5').hide();
        $('.form-group-4').show();
    });
    
    form.on('click', '#vehicleManual', function (e) {
        e.preventDefault();
        $('.panel-body div.alert-danger').remove();
        $('.form-group-2').show();
        $('.form-group-5').show();
        $('.form-group-4').hide();
        $('.form-group-6').hide();
    });

    // Toggle fields that should be shown for this field
    //form.on('change', '[name=state],[name=type]', function(e){
    function resetFields() {
        $('.IL-only').hide();
        $('.click-danger').hide();
        form.find('.registrant-information').hide();
        form.find('.operator-information').hide();
        // Iterate over each input to toggle the visibility of the field
        form.find(':input').each(function(i, obj)
        {
            // Get the field name in question
            var name = $(obj).attr('name');
            if( window.fields[name] === undefined )
                return;

            // Get the field
            var fields = window.fields[name];
            var field = form.find('[name="' + name + '"]').parents('.form-group');
            if( ! field.length ) {
                field = form.find('[name^="' + name + '"]').parents('.form-group');
            }
            field.hide();
            field.children('input').val('');
            
            if ( ! field.length ) {
                field = form.find('[name="' + name + '"]').parents('.form-group-2');
                var exclusion = ['vehicle_make','vehicle_year','vehicle_vin','license_plate_number','state_of_issuance'];
                if (!exclusion.includes(name)) {
                    field.removeClass('req');
                }
            }
        });
    }
    function addFields(state,type) {
        // Reset the panel state
        form.find('.panel .alert-warning:not(.req)').hide();

        // Switch between mailing and physical address fields for some states
        if ( inArray(state, ['IL']) ) {
            $('.IL-only').show();
        }
        /*
        if ( inArray(state, ['NC']) || inArray(state, ['PA']) ) {
            $('.physical-address').hide();
            $('.registrant-mailing-alt').show();
            $('.registrant-mailing-alt .form-group').addClass('show');
        } else {
            $('.registrant-mailing-alt').hide();
            $('.physical-address').show();
        }
        */

        // Toggle Registrant and Operator Information titles
        if( inArray(state, ['TX']) )
        {
            form.find('.operator-information').show();
        } else {
            form.find('.registrant-information').show();
        }

        // Iterate over each input to toggle the visibility of the field
        form.find(':input').each(function(i, obj)
        {
            // Get the field name in question
            var name = $(obj).attr('name');
            if( window.fields[name] === undefined )
                return;

            // Get the field
            var fields = window.fields[name];
            var field = form.find('[name="' + name + '"]').parents('.form-group');
            if( ! field.length ) {
                field = form.find('[name^="' + name + '"]').parents('.form-group');
            }
            
            if ( ! field.length ) {
                field = form.find('[name="' + name + '"]').parents('.form-group-2');
            }

            // Show the field
            if( inArray(state, fields[type]) ||
                ( type === 'combo' && (inArray(state, fields.fuel) || inArray(state, fields.trip)) )
            ) {
                if (field.hasClass('form-group-2')) {
                    field.addClass('req');
                } else {
                    field.show();
                }
            }
        });

        // Toggle the panels when hiding the fields hides all fields in a panel
        var panels = form.find('.panel-body');
        panels.each(function(i, obj)
        {
            var panel = $(obj);
            if( panel.find('.form-group:visible').length === 0 )
            {
                panel.find('.alert-warning').show();
            }
        });

        // Toggle checkbox fields
        form.find('[name=registrant_mailing]').trigger('change');
        form.find('[name=owner_mailing]').trigger('change');
        form.find('[name=owner_registrant]').trigger('change');
    }

    function animateStep(num) {
        var a = $('#step'+num+' i');
        a.css('padding-left',0);
        setTimeout(function () {
            a.css('padding-left',5);
        }, 500);
        setTimeout(function () {
            a.css('padding-left',10);
        }, 1000);
        setTimeout(function () {
            a.css('padding-left',15);
        }, 1500);
        setTimeout(function () {
            a.css('padding-left',20);
        }, 2000);
    }
    animateStep('1');
    step1interval = setInterval(function(){animateStep('1');}, 2500);

    // Toggle mailing address for registrant
    var registrant_mailing = form.find('[name=registrant_mailing]');
    registrant_mailing.on('change', function(e){
        var address = form.find('.registrant-mailing');
        if( registrant_mailing.is(':checked') )
        {
            address.hide();
            address.find(':input').val('');
        }
        else
        {
            address.show();
        }
    });

    // Toggle mailing address for owner
    var owner_mailing = form.find('[name=owner_mailing]');
    owner_mailing.on('change', function(e){
        var address = form.find('.owner-mailing');
        if( owner_mailing.is(':checked') )
        {
            address.hide();
            address.find(':input').val('');
        }
        else
        {
            address.show();
        }
    });

    // Toggle owner information when owner is registrant
    var owner_registrant = form.find('[name=owner_registrant]');
    owner_registrant.on('change', function(e){
        var physical = form.find('.owner-physical');
        var mailing = form.find('.owner-mailing');
        var fields = form.find('.owner-registrant-information');
        if( owner_registrant.is(':visible') )
        {
            if (owner_registrant.is(':checked') )
            {
                // Hide owner information
                physical.hide();
                physical.find(':input').val('');
                mailing.hide();
                mailing.find(':input').val('');
                fields.hide();
                form.find('[name=owner_mailing]').prop('checked', false);
            }
            else
            {
                physical.show();
                mailing.show();
                fields.show();
            }
        }
    });

    // Fetch the vehicle information
    form.on('change', 'select[name="vehicle_id"]', function(e)
    {
        $('.panel-body div.alert-danger').remove();
        $('.form-group-6').hide();
        
        // Get the ID of the vehicle
        var id = $(e.currentTarget).val();
        var fieldsets = form.find('.insurance-fieldset, .make-fieldset, .vin-fieldset, .license-fieldset');

        // Reset the fieldset when the user chooses to add a new vehicle
        if( ! id ) {
            fieldsets.attr('disabled', false);
            fieldsets.find(':input').val('');
            $('.form-group-2').hide();
            $('.form-group-4').show();
            return;
        }

        // Disable inputs while the AJAX request is loading
        fieldsets.attr('disabled', true);

        // Update all the fields based on the API call
        var url = '/vehicles/' + id;
        $.get(url, [], function(vehicle)
        {
            form.find('[name="insurance_name"]').val(vehicle.insurance_name);
            form.find('[name="insurance_policy_number"]').val(vehicle.insurance_policy_number);
            form.find('[name="vehicle_make"]').val(vehicle.make);
            form.find('[name="vehicle_model"]').val(vehicle.model);
            form.find('[name="vehicle_year"]').val(vehicle.year);
            form.find('[name="vehicle_vin"]').val(vehicle.vin);
            form.find('[name="vehicle_style"]').val(vehicle.style);
            form.find('[name="vehicle_registration_county"]').val(vehicle.registration_county);
            form.find('[name="max_gross_weight"]').val(vehicle.max_gross_weight);
            form.find('[name="fuel_type"]').val(vehicle.fuel_type);
            vehicle.axles = (vehicle.axles > 1) ? vehicle.axles : 2;
            form.find('[name="vehicle_axles"]').val(vehicle.axles);
            form.find('[name="license_plate_number"]').val(vehicle.license_plate_number);
            form.find('[name="license_plate_expiration"]').val(vehicle.license_plate_expiration);
            form.find('[name="city_of_issuance"]').val(vehicle.city_of_issuance);
            form.find('[name="state_of_issuance"]').val(vehicle.state_of_issuance);
            
            $('.form-group-4').hide();
            $('.form-group-5').hide();
            fieldsets.attr('disabled', false);
            $('.form-group-2').show();

        }, 'json')

        // Failure callback
        .fail(function(jqXhr, textStatus, errorThrown)
        {
            var error = jqXhr.responseJSON;
            console.log(errorThrown);
            fieldsets.attr('disabled', false);
        });
    });
    resetFields();
    // Initialize the states
    //form.find('[name=state]').trigger('change');

})(window.$);

$(window).on('pageshow',function(){
    // If we have local data, populate form from that
    if (localStorage.getItem('states')) {
        var types = JSON.parse(localStorage.getItem('permit_types'));
        $.each(JSON.parse(localStorage.getItem('states')),function(i,state){
            form.find('[name=state]').val(state).trigger('change');
            form.find('[name=type][value="'+types[i]+'"]').prop('checked',true).trigger('change');
            $('#addPermitBtn').trigger('click');
        });

        var data = {};
        $.each(JSON.parse(localStorage.getItem('order')),function(i,pair){
            data[pair.name] = pair.value;
        });
        var els = form.find(':input').get();
        $.each(els, function() {
            switch(this.name) {
                case '_token':
                case 'states':
                case 'state':
                case 'permit_types':
                case 'effective_at[date]':
                case 'effective_at[time]':
                return;
            }
            if (this.name && data[this.name]) {
                if(this.type == 'checkbox' || this.type == 'radio') {
                    $(this).attr("checked", (data[this.name] == $(this).val()));
                } else {
                    $(this).val(data[this.name]);
                }
            }
        });
        // Toggle checkbox fields
        form.find('[name=registrant_mailing]').trigger('change');
        form.find('[name=owner_mailing]').trigger('change');
        form.find('[name=owner_registrant]').trigger('change');
        // Show vehicle fields if vehicle id has been selected
        if (form.find('[name=vehicle_id]').val()) {
            $('.form-group-4').hide();
            $('.form-group-2').show();
            $('.orders-create button[type=submit]').removeClass('disabled');
        }
        // Enable button
        $('[data-toggle="loading"]').prop('disabled', false).text('Preview Permits').removeClass('disabled');
    }
});
function inArray(needle, haystack)
{
    return haystack.indexOf(needle) >= 0;
}

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/common/_order.js","/common")