(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
'use strict';

/**
 * Default Esensi modal
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
require('./_bootstrap');

// Require custom dependencies
window.Esensi = require('../common/_esensi');

// Add modal to the Esensi namespace
window.Esensi.modal = {};
window.Esensi.modal.context = window.$('#esensiModal');

// Add an init method to the modal method
window.Esensi.modal.init = function () {

};

(function($) {

    // Fix for modal reloads
    // http://stackoverflow.com/questions/12286332/
    $('#esensiModal').on('hidden.bs.modal', function () {
        $(this).removeData('bs.modal').find('.modal-content').empty();
    });
    $('#esensiModal').on('shown.bs.modal',function (e) {
        $('.navmenu.offcanvas-sm').offcanvas('toggle');
    });
})(window.$);

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/public/_modal.js","/public")