(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Scroll up to the top of the modal when it opens.
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
require('./_modal');

// Do something after DOM is ready
(function ($) {

    var $modal = window.Esensi.modal.context;

    $modal.on('loaded', function(){

        if($modal.find('[data-toggle="payonline"]').length){
            $modal.css({
                position: 'absolute',
                bottom: 'auto'
            });
            $('html,body').animate({
                scrollTop: 0,
            }, 1000);
        }
    });

})(window.$);

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/public/_preview.js","/public")