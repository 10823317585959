(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Main application scripts
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');

// Do something after DOM is ready
(function ($) {

    var $body = $('body.public.orders-start');
    var $button = $('.orders-start .btn-new-account');
    var $password = $body.find('#new-customer-password');
    var $password_confirm = $body.find('#new-customer-password-confirm');
    var $form = $button.parents('form');
    var $alert = $body.find('.password-error');

    $alert.hide();

    $button.on("click", function(_event){
        _event.preventDefault();

        var password_value = $password.val();
        var confirm_value = $password_confirm.val();
        $alert.hide();

        if( password_value !== confirm_value )
        {
            $alert.show();
            $password.val('');
            $password_confirm.val('');
        } else {
            $form.submit();
        }

    });

})(window.$);

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/public/_new-account.js","/public")