(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Order Form Calculator
 *
 * @see OrderApiController@calculateFees
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');

module.exports = function calculateFees(form) {

    var config = window.permit;
    var state = form.find('[name=state]').val();
    var method = form.find('[name=delivery_method]:checked').val() || 'download';
    var weight = form.find('[name=max_gross_weight]').val();
    var type = form.find('[name=type]:checked').val();

    if(type && state && method)
    {
        var hasComboPermits = config.types.combo.indexOf(state) !== -1;
        var count = (type !== 'combo' || (type === 'combo' && hasComboPermits)) ? 1 : 2;
        var permit_fees = config.permit_fees[type][state];
        var processing_fees = config.processing_fees[type][state];
        var delivery_fees = config.delivery_fees[method] * count;
        if (state in config.processing_fees.overladen.limit[type]) {
            if (weight > config.processing_fees.overladen.limit[type][state]) {
                var overladen_fees = config.processing_fees.overladen.fee[type][state];
                var total_fees = permit_fees + processing_fees + delivery_fees + overladen_fees;
                if(overladen_fees) form.find('span.overladen-fees').text(overladen_fees.toFixed(2));
            }
        } else {
            var total_fees = permit_fees + processing_fees + delivery_fees;
        }

        if(permit_fees) form.find('span.permit-fees').text(permit_fees.toFixed(2));
        if(processing_fees) form.find('span.processing-fees').text(processing_fees.toFixed(2));
        if(delivery_fees) form.find('span.delivery-fees').text(delivery_fees.toFixed(2));
        if(total_fees) form.find('span.total-fees').text(total_fees.toFixed(2));
    }
};
}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/common/_calculator.js","/common")