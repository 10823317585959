(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
'use strict';

/**
 * Helper function for binding selectize to inputs
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
require('selectize');

// Export taggify() method
module.exports  = function taggify(context){

    // Bind selectize to inputs
    window.$('input.esensi-tags', context).selectize({
        persist: false,
        create: true,
        createOnBlur: true,
        plugins: ['remove_button']
    });

    // Bind selectize to selects
    window.$('select.esensi-tags', context).selectize({
        persist: false,
        plugins: ['remove_button']
    });
};

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/common/_taggify.js","/common")