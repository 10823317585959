(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
"use strict";

/**
 * Public application scripts
 */

// Require vendor dependencies
window.$ = window.jQuery = require('jquery');
// require('jQuery-ajaxTransport-XDomainRequest');
require('./public/_bootstrap');
require('jasny');

// Bootstrap the application
require('./common/_start');

var taggify = require('./common/_taggify'); taggify();

require('./public/_modal');
require('./public/_fb-button.js');
require('./public/_feedback.js');
require('./public/_preview');
require('./common/_order');
require('./common/_gateway');
require('./public/_new-account');

// Do something after DOM is ready
(function ($) {
    // Make the loading buttons work
    $(document).on('click', '[data-toggle="loading"]', function(){
        $(this).button('loading');
    });

    // Make the confirm preview modal submit the background form
    $(document).on('click', '[data-toggle="payonline"]', function(){
        $(this).button('loading');
        $('form.orders-preview').submit();
    });

    // Make the confirm preview modal submit the background form from anchor
    $(document).on('click', '.proceed-to-payment', function(){
        $('[data-toggle="payonline"]').button('loading');
        $('form.orders-preview').submit();
    });

    // Show BS tooltips
    $('[data-toggle="tooltip"]').tooltip();

    // @see http://jordanhollinger.com/2012/06/08/disable-the-back-button-using-html5/
    // It works without the History API, but will clutter up the history
    var history_api = typeof window.history.pushState !== 'undefined';

    // The previous page asks that it not be returned to
    if ( window.location.hash === '#no-back' )
    {
        // Push "#no-back" onto the history, making it the most recent "page"
        if ( history_api ) {
            history.pushState(null, '', '#stay');
        } else {
            window.location.hash = '#stay';
        }

        // When the back button is pressed, it will harmlessly change the url
        // hash from "#stay" to "#no-back", which triggers this function
        window.onhashchange = function()
        {
            // User tried to go back; warn user, rinse and repeat
            if ( window.location.hash === '#no-back' ) {
                window.location = '/orders';
                if ( history_api ) {
                    history.pushState(null, '', '#stay');
                } else {
                    window.location.hash = '#stay';
                }
            }
        };
    }
})(window.$);

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_a1362279.js","/")