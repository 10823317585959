(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
'use strict';

/**
 * Global application start
 */

// Require custom dependencies
require('./_plugins');
require('./_esensi');

// Run Google Analytics
require('./_ga');

}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/common/_start.js","/common")